const APPLICATION_JSON = 'application/json';

export async function getAsync(url) {
    const response = await fetch(url);

    return await response.json();
}

export async function postAsync(url, body) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': APPLICATION_JSON,
            'Content-Type': APPLICATION_JSON
        },
        body: JSON.stringify(body),
    });

    return await response.json();
}
