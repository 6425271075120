import React, { Component } from 'react';
import '../style/header.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

library.add(faFacebookSquare, faInstagram, faTwitter);

class UNXFooter extends Component { 
    render() {
        return (
            <div>
                <div className="footerSection">
                     <div className="linkDiv">
                        <Link className="footerLink" to='/help'>Help</Link>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://unx-now-main-storage.s3.amazonaws.com/legal/UNX+Privacy+Policy.pdf" target="_blank">Privacy Policy</a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://unx-now-main-storage.s3.amazonaws.com/legal/UNX+Terms+of+Use.pdf" target="_blank">Terms of Use</a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://unx-now-main-storage.s3.amazonaws.com/legal/UNX+Cookie+Policy.pdf" target="_blank">Cookie Policy</a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://twitter.com/unx_now?lang=en" target="_blank" style={{fontSize: "2.5em"}}><FontAwesomeIcon icon={['fab', 'twitter']}/></a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://www.instagram.com/unx.now/?hl=en" target="_blank" style={{fontSize: "2.5em"}}><FontAwesomeIcon icon={['fab', 'instagram']}/></a>
                    </div>
                    <div className="linkDiv">
                        <a className="footerLink" href="https://www.facebook.com/UNXNow1/" target="_blank" style={{fontSize: "2.5em"}}><FontAwesomeIcon icon={['fab', 'facebook-square']}/></a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });

const mapDispatchToProps = { };


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UNXFooter));
