import { BASE_URL } from "./constants";

const baseUrl = `${BASE_URL}stats/`;

export async function getEventListAsync() {
    const url = `${baseUrl}get_event_list`;
    const response = await fetch(url);
    const json = await response.json();
    console.log('getEventListAsync', json);

    return json;
}

export async function getEventResultsAsync(eventId) {
    const url = `${baseUrl}get_event_results`;
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ event_id: eventId })
    };

    const response = await fetch(url, options);

    return await response.json();
}

export async function getSeasonStandingsAsync() {
    const url = `${baseUrl}get_season_standings`;
    const response = await fetch(url);

    return await response.json();
}