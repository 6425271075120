import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const logOutUser = () => {};


library.add(faUser, faBars, faChevronDown);

export default function SimpleMenuAlt(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogOut = () =>{
    logOutUser().then(resp=>{
        console.log('log out');
        console.log(resp);
        window.location ='/sign_in';
        props.logoutUserFromReduxState();
    });
}

  // var toggleDropDown = false;
  const [toggleDropDown, setToggleDropDown] = React.useState(false);

  const toggleDiv = (val) =>{
    console.log(val);
    var divState;
    if(val==true){
      divState = false;
    }else{
      divState = true;
    }
    setToggleDropDown(divState);
  }




  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
      backgroundColor: "rgb(40,44,52)"
    },
  })(props => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  return (
    <div>
      <div className="menuIcon" onClick={handleClick}><FontAwesomeIcon className="headerLink" icon="bars"></FontAwesomeIcon></div>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{backgroundColor: "rgba(40,44,52,0.9"}}
      >
        <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}} onClick={e=>toggleDiv(toggleDropDown)}>
          <div className="headerLink">Videos <FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon></div>
          </MenuItem>
          {toggleDropDown == true ? 
                  <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink navDropItem" to="/dashboard">Dashboard</Link></MenuItem>
                  : null}
          {toggleDropDown == true ? 
                  <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink navDropItem" to="/video_collection?type=video_type_id&id=1">Competitions</Link></MenuItem>
                  : null}
                  {toggleDropDown == true ? 
                  <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink navDropItem" to="/video_collection?type=video_type_id&id=2">UNX Fast Forward</Link></MenuItem>
                  : null}
                  {toggleDropDown == true ? 
                  <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink navDropItem" to="/video_collection?type=video_type_id&id=3">Athlete Full Runs</Link></MenuItem>
                  : null}
        <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink" to='/about_us'>About Us</Link></MenuItem>          
        <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink" to='/unx_championship_series'>UNX Championship Series</Link></MenuItem>          
        <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink"  to='/leaderboard'>Leaderboard</Link></MenuItem>
        <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><Link className="headerLink"  to='/ninja_near_you'>Compete</Link></MenuItem>
        <MenuItem style={{backgroundColor: "rgba(40,44,52,0.9)"}}><a className="headerLink" href='https://www.youtube.com/channel/UClhKt2DfYg9EjgcWbT1LWaA'>YouTube</a></MenuItem>
      </StyledMenu>
    </div>
  );
}