import React, {Component} from 'react';
import UNXHeader from "../components/header";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/account.css';
import '../style/leaderboard.css';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCreditCard} from '@fortawesome/free-solid-svg-icons';
import loadingImg from '../static/unx_loading.png';
import {getEventListAsync, getEventResultsAsync, getSeasonStandingsAsync} from '../api/stats';
import { Row } from 'simple-flexbox';
import Logo from '../static/unx_logo.png';

library.add(faCreditCard);


class Leaderboard extends Component {

  state={
    eventArr: [],
    resultsArr: [],
    selectedEvent: '',
    selectedObject: {},
    loading: true,
    showLeaderboard: false,
    toggleMobileLeaderboard: false,
    leaderboard: []
  }

  getResults = e =>{
    // console.log(e.target.value);
    var selectedEvent = e.target.value;
    const selectedEventFromState = this.state.eventArr[selectedEvent];
    if (selectedEventFromState) {
        getEventResultsAsync(this.state.eventArr[selectedEvent].event_id).then(resp=>{
            this.setState({showLeaderboard: true, resultsArr: resp.results, selectedEvent:  this.state.eventArr[selectedEvent].event_name, selectedObj:  this.state.eventArr[selectedEvent]});
        });
    } else {
        this.setState({ showLeaderboard: false, resultsArr: [], selectedEvent: '', selectedObj: {}, selectedObject: {} });
    }
  }

      toggleMobileLB(){
            var divState;
            if(this.state.toggleMobileLeaderboard==true){
              divState = false;
            }else{
              divState = true;
            }
            this.setState({toggleMobileLeaderboard: divState});
    }


componentDidMount(){
  // console.log(this);
    getEventListAsync().then(resp=>{
        // console.log(resp);
        getSeasonStandingsAsync().then(resp2=>{
          this.setState({eventArr: resp.events, loading: false, leaderboard: resp2.results});
        })
    })
  }


    render() {
      if(this.state.loading == true){
        return (<div className="mainDiv" style={{height: "100vh"}}>
            <UNXHeader title='' link='' linkTitle=''></UNXHeader>
            <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                <div className="pulseImg">
                <img src={loadingImg} width="200px" height="auto" />
                </div>
                </div>
                </div>
        )
    }else{
      return (
          <div className="mainDiv">
            <UNXHeader title='' link='' linkTitle=''></UNXHeader>
            <div className="pageContainer" style={{marginTop: "10px"}}>
            <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(255,255,255)"}}>Competition Results</div>
            <div className="selectOptions">
            <select className="selectBox" style={{fontSize: '14px'}} value={this.state.selectedObject.event_name} onChange={e=>this.getResults(e)}>
                                       <option value=''>Select Competition</option>
                                       {this.state.eventArr.map((item,index)=>{
                                         return <option value={index}>{item.event_name}</option>
                                       })} 
                                    </select>
                              </div>

                  {this.state.showLeaderboard == true ?
                        <div>
                        <div className="subTitle">{this.state.selectedEvent}</div>
                        
                        <div className="leaderboardDivision">
                            
                            <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                <div className="athleteBox">Men</div>
                                <div className="scrollLink"><span href="javascript:void(0)" onClick={e=>this.toggleMobileLB()}>Show Women</span></div>
                                {this.state.resultsArr.filter(gen=>gen.gender=="Male").map((item, index) => {
                                        return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"}>
                                            <Row horizontal='space-around' vertical='center'>
                                                <div>{item.rank}.</div>
                                                <div>{item.first_name + ' ' + item.last_name}</div>
                                                <div><div>{item.points}</div><div style={{fontSize: ".6em"}}>Pts.</div></div>
                                            </Row>
                                            <Row horizontal='center'>
                                              {item.tiebreaker_time != null ? <div className="tieTime">{item.tiebreaker_time.substr(3)}</div> : null}
                                            </Row>
                                        </div>
                                })}
                        </div>

                        <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision" : "athleteDivision hideMobile"}>
                                <div className="athleteBox">Women</div>
                                <div className="scrollLink"><span onClick={e=>this.toggleMobileLB()}>Show Men</span></div>
                                {this.state.resultsArr.filter(gen=>gen.gender == "Female").map((item, index) => {
                                    if(item.gender == "Female"){
                                        return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"}>
                                            <Row horizontal='space-around' vertical='center'>
                                                <div>{item.rank}.</div>
                                                <div>{item.first_name + ' ' + item.last_name}</div>
                                                <div><div>{item.points}</div><div style={{fontSize: ".6em"}}>Pts.</div></div>
                                            </Row>
                                            <Row horizontal='center'>
                                                {item.tiebreaker_time != null ? <div className="tieTime">{item.tiebreaker_time.substr(3)}</div> : null}
                                            </Row>
                                        </div>
                                    }
                                })}
                        </div>
                        </div>
                        
                        </div>: null}


                        <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(255,255,255)"}}>Season Standings</div>

        <div>
        <div className="scoreboard" style={{maxHeight: "none"}}>
                        <div>
                        
                        <div className="leaderboardDivision">
                            
                            <div className="athleteDivision">

                              <div className="scoreboardTitleBox">
                                <div><img alt='unx_logo' src={Logo} width="175" height="auto"/></div>
                                <div style={{textAlign: "center"}}>
                                  <div className="scoreboardTitle">UNX Season Standings</div>
                                    <div className="scoreboardSubTitle">
                                        <span>Top 8 Advance to Championship</span>  
                                      </div>
                                </div>
                                <div><img alt='unx_logo' src={Logo} width="175" height="auto"/></div>
                              </div>

                              <div className="leaderboardDivision">

                              <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                <div className="athleteBox" style={{color: "rgb(255,255,255)", backgroundColor: "rgb(10,10,10)"}}>Men</div>

                              <div style={{marginTop: "10px"}}>
                                <table className="scoreboardTable" style={{width: "100%", borderCollapse: "collapse"}}>
                                  <thead className="tableHeader">
                                    <th></th>
                                    <th style={{textAlign: "left"}}>ATHLETE</th>
                                    <th>Points</th>
                                  </thead>
                                  <tbody>
                                    {this.state.leaderboard.filter(gen=>gen.gender=="Male").map((item, index) => {
                                          return <tr className="standingsBox" style={{borderTop: "4px solid rgb(97, 218, 251)"}}>
                                                  <td className="rankCell">{item.rank}</td>
                                                  <td className="nameCell">{item.first_name} <strong>{item.last_name}</strong></td>
                                                  <td className="locCell">{item.points}</td>
                                          </tr>
                                  })}
                                  </tbody>
                                </table>
                              </div>
                        </div>

                        <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                <div className="athleteBox" style={{color: "rgb(255,255,255)", backgroundColor: "rgb(10,10,10)"}}>Women</div>

                              <div style={{marginTop: "10px"}}>
                                <table className="scoreboardTable" style={{width: "100%", borderCollapse: "collapse"}}>
                                  <thead className="tableHeader">
                                    <th></th>
                                    <th style={{textAlign: "left"}}>ATHLETE</th>
                                    <th>Points</th>
                                  </thead>
                                  <tbody>
                                    {this.state.leaderboard.filter(gen=>gen.gender=="Female").map((item, index) => {
                                          return <tr className="standingsBox" style={{borderTop: "4px solid rgb(97, 218, 251)"}}>
                                                  <td className="rankCell">{item.rank}</td>
                                                  <td className="nameCell">{item.first_name} <strong>{item.last_name}</strong></td>
                                                  <td className="locCell">{item.points}</td>
                                          </tr>
                                  })}
                                  </tbody>
                                </table>
                              </div>
                        </div>

                        </div>
                        </div>
                        </div>
                        
                        </div>
          </div>
        </div>
              
              </div>
              <UNXFooter title='' link='' linkTitle=''></UNXFooter>

          </div>
      );
                  }
    }
  }


  const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
   };

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
