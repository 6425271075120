import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import rootReducer from './reducers'
 
const persistConfig = {
  key: 'unx-amplify-root',
  storage: new CookieStorage(Cookies, {
    expiration: {
      'default': 86400
    }
  })
};
 
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(persistedReducer, applyMiddleware(reduxThunk));
export const persistor = persistStore(store);
