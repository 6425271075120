import { BASE_URL } from "./constants";
import { getAsync, postAsync } from "./helpers";

const videoUrl = `${BASE_URL}videos/`;
const openUrl = `${BASE_URL}open/`;

export async function getFeaturedVideosAsync(subStatus) {
    const response = await getAsync(`${videoUrl}get_featured_videos?sub_status=${subStatus}`);
    console.log('getFeaturedVideosAsync', response);

    return response['videos'];
}

export async function getSearchTextAsync() {
    const response = await getAsync(`${videoUrl}get_searchable_text`);
    console.log('getSearchTextAsync', response);

    return response['searchArr'];
}

export async function getVideoCollectionAsync(type, id, subStatus, eventId, searchTerm = '') {
    const response = await postAsync(`${videoUrl}get_video_collection`, { type, id, sub_status: subStatus, source_id: eventId, search_term: searchTerm });
    console.log('getVideoCollectionAsync', response);

    return response;
}

export const getRequestedVideoAsync = async (videoId, subStatus) => await postAsync(`${videoUrl}get_requested_video`, { video_id: videoId, sub_status: subStatus });
export const addVideoClickAsync = async (videoId) => await postAsync(`${videoUrl}add_video_click`, { video_id: videoId });
export const getHomePageVideosAsync = async () => await getAsync(`${openUrl}get_home_page_videos`);
export const getDailyVideoAsync = async () => getAsync(`${openUrl}get_daily_video`);