// we import all the various reducers we created, to be combined using the Redux function `combineReducers()`
import { combineReducers } from 'redux';
// import sideMenuReducer from './sideMenuReducer'
import authReducer from './auth_reducer';

import { connectRouter } from 'connected-react-router';


//************************************************ */
//Persisted reducer configuration 
//************************************************ */

const rootReducer = combineReducers({
  auth: authReducer
})

export default rootReducer

//************************************************ */
//************************************************ */

//************************************************ */
//Connected Router reducer configuration 
//************************************************ */

// export default (history) => combineReducers({
//   router: connectRouter(history),
//   auth: authReducer
// })

//************************************************ */
//************************************************ */

//************************************************ */
//Orignal reducer configuration 
//************************************************ */

// pass in an object to combineReducers() that represents a mapping of each reducers
// inside <SideMenu> (ie ../components/SideMenu/SideMenu.js) we can reference the `visible` attribute with `state.sideMenu.visible`
// inside <Home> (ie ../components/home.js) we can reference the `myContent` attribute with `state.content.myContent`
// const rootReducer = combineReducers({
//   auth: authReducer
// })

// // export the combined reducers for use inside `../store.js` to create the Redux store (aka the Redux state)
// export default rootReducer

//************************************************ */
//************************************************ */
