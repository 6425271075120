import React, {Component} from 'react';
import UNXHeaderAlt from "../components/header_alt";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/home.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addUfAtHomeEmailAsync } from "../api/submission";



library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp);


class ultifitAtHome extends Component {

  state={
    first_name: '',
    last_name: '',
    email: ''
  }
    
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  closeDialog = (type) =>{
    this.setState({ openDialog: false, dialogTitle: "", dialogMsg: "" });
  }

  toggleDiv(type){
    var divState;
    if(this.state[type]==true){
      divState = false;
    }else{
      divState = true;
    }
    this.setState({[type]: divState});
  }

  submitEmail(){
    addUfAtHomeEmailAsync(this.state.first_name, this.state.last_name, this.state.email).then(resp=>{
      console.log(resp);
      this.setState({first_name: '', last_name: '', email: '', openDialog: true, dialogTitle: 'Thank You, your email has been submitted', dialogMsg: 'You will receive an email update when ultiFIT @ Home is ready for purchase!'});
    })
  }

  goTo(str){
    this.props.history.push('/' + str);
}


    componentDidMount() {
        console.log(this);
      }

    render() {
      return (
          <div className="homeDiv">
            <UNXHeaderAlt title='' link='' linkTitle=''></UNXHeaderAlt>
            <div id="ufAtHomeImg">
                            <div style={{position: "absolute", bottom: "0px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(57,177,211)", width: "100%", textAlign: "center", padding:"10px"}}>ultiFIT AT Home</div>
                  </div>
            <div className="pageContainer">
            <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>Bar System</div>
                
                <div className="flex-responsive-row" style={{flexWrap: "wrap", alignItems: "inherit"}}>

                <div className="mainVideoContainer flexOne" style={{padding: "10px", minWidth: "300px", flex: "1"}}>
                  <div style={{width: "100%"}}>
                    <div class='embed-container'>
                            <iframe className="mainVideo" src={"https://player.vimeo.com/video/371564845?wmode=transparent"} wmode="Opaque" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                      </div>
                    <div style={{margin: "0px", borderRadius: "0px", backgroundColor: "rgb(255,255,255)", padding: "10px"}}>
                      <div className="sectionTitle" style={{fontFamily: "VIPESSRG", color: "rgb(57,177,211)", fontSize: "1.5em", letterSpacing: ".5px"}}>
                        ultiFIT At Home Bar System
                      </div>
                        <div className="sectionDesc" style={{color: "rgb(100,104,112)"}}>
                          Sign up on our email list below to stay up to date with the at home bar system!
                        </div>
                      </div>
                    </div>

                </div>

                <div className="flexOne" style={{margin: "10px"}}>
                    <div className="whiteCard" style={{ flex: "1", position: "relative", borderBottom: "3px solid rgb(57,177,211)"}}>
                        <div className="whiteCardContainer">
                        <div className="whiteCardText" style={{fontSize: ".8em"}}>
                            <div>UNX is launching a unique at home ninja experience 
                        with the ultiFIT @ Home bar system. Train with professional ninjas with state of the art ninja 
                        equipment paired with full workouts by professional ninja athletes all from the comfort of your own home!</div>
                        <div style={{marginTop: "15px"}}>Be the first to hear when ultiFIT @ Home System is available for purchase</div>
                        </div>
                        <div className="flex-responsive-row" style={{flexWrap: "wrap"}}>
                            <div><input name='first' className="authInput" placeholder='First Name' value={this.state.first_name} onChange={this.handleChange('first_name')}/></div>
                            <div><input name='last' className="authInput" placeholder='Last Name' value={this.state.last_name} onChange={this.handleChange('last_name')}/></div>
                            <div><input name='email' className="authInput" placeholder='Email' value={this.state.email} onChange={this.handleChange('email')}/></div>
                        </div>
                            </div>
                            <div style={{height: "50px"}}></div>
                            <div style={{width: "100%", position: "absolute", bottom: "0"}}><button type="button" className="unxBtn" style={{fontSize: ".75em", borderRadius: "0px", padding: "15px 10px"}} onClick={e=>this.submitEmail()}>Submit</button></div>
                        </div>
                    </div>
                    
                </div>

               
          <div className="flex-responsive-row">
                    <div className="flexOne" style={{margin: "10px"}}>
                      <img src="https://unx-now-main-storage.s3.amazonaws.com/images/ultiFIT_At_Home/unx_bar_system_v1.jpg" style={{minWidth: "300px"}} width="100%" height="auto"/>
                      {/* <div className="aspect-ratio-box" style={{backgroundPosition: "0% 25%", backgroundImage: `url(https://unx-now-main-storage.s3.amazonaws.com/images/ultiFIT_At_Home/unx_bar_system_v1.jpg)`, borderRadius: "0px"}} ></div> */}
                    </div>
                    <div className="flexOne" style={{margin: "10px"}}>
                      <img src="https://unx-now-main-storage.s3.amazonaws.com/images/ultiFIT_At_Home/unx_bar_system_v2.jpg" style={{minWidth: "300px"}} width="100%" height="auto"/>
                      {/* <div className="aspect-ratio-box" style={{backgroundPosition: "0% 25%", backgroundImage: `url(https://unx-now-main-storage.s3.amazonaws.com/images/ultiFIT_At_Home/unx_bar_system_v2.jpg)`, borderRadius: "0px"}} ></div> */}
                    </div>
          </div>
                
                <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>ultiFIT At Home FAQ's</div>
                <div className="whiteCard" style={{ flex: "2", borderBottom: "3px solid rgb(57,177,211)"}}>
                        <div className="whiteCardContainer">
                        <div className="whiteCardText" style={{fontSize: ".75em", textAlign: "left"}}>
                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> How much does the ultiFIT @ Home workout system cost?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> A singular ultiFIT system is currently $1399.00 with accessories included</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> What is the size of the ultiFIT @ Home workout system?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> Approximately 7' wide x 7' deep x 7' high.</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> Where can I put the ultiFIT @ Home workout system? </div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> The ultiFIT @ Home system is metal with a powder coating and therefore can be placed anywhere you have room! That can be a garage, basement, extra bedroom or even outside!</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> How fit do I have to be to use the ultiFIT @ Home workout system?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> Whether you're starting your very first fitness journey, spicing up your current routine, or looking to take your ninja skills to the next level...the ultiFIT @ Home rig and daily workouts are for you! Each workout is professionally hand crafted to challenge you at any fitness level with our 3 tier scale-able routines.</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> Can adults and kids use the ultiFIT @ Home workout system?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> The ultiFIT @ Home rig was designed with both kids and adults in mind. Calisthenics is effective and appropriate for all ages, especially kids! </div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> What are the workouts going to be like?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> Challenging, Fun, and Effective! Our ultiFIT co-creators have over 20 years of experience designing workouts for individuals of all ages and abilities. All workouts will be calisthenics based and will challenge every muscle in your body to ensure a well balanced and scientifically sound routine!</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> How long are the workouts going to be?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> 20-30 mins MAX</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> How often are workouts going to be released?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> Ultifit @ Home workouts will be released every Monday through Friday.</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> Do I need to have a subscription to UNX to purchase the ultiFIT @ Home workout system? </div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> You do not need a UNX Now subscription to purchase the Ultifit @ Home system, but you will need a UNX Now monthly subscription for access to video workout designed by our professional ninjas.</div>

                        <div style={{marginTop: "15px", color: "rgb(150,150,150)"}}><strong>Q:</strong> How do I get access to the ultiFIT @ Home workouts?</div>
                        <div style={{marginTop: "5px", color: "rgb(60,64,72)", marginLeft: "20px"}}><strong>A:</strong> Ultifit @ Home workouts are included in a UNX Now premium subscription for $9.99/month.</div>
                        </div>
                        </div>
                        </div>

            </div>
              <UNXFooter title='' link='' linkTitle=''></UNXFooter>

              <Dialog
                open={this.state.openDialog}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                            {this.state.dialogMsg}      
                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <button type="button" onClick={this.closeDialog} className="stripeButton">
                                    Ok
                    </button>
                            </DialogActions>
                        </Dialog>

          </div>
      );
    }
  }


export default ultifitAtHome;
