import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter} from 'react-router-dom';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: ''
    };
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.search.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown

    localStorage.setItem('unx-search-term', (userInput || '').toLowerCase());
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = obj => {
    const { athlete_id, search } = obj;
    const searchTerm = (search || '').toLowerCase();
    this.props.history.push({
      pathname: '/' + 'video_collection',
      search: `?type=athlete_id&id=${athlete_id}&search_term=${searchTerm}`,
      state: {type: 'athlete_id' , id: athlete_id, search_term: searchTerm }
    });
  };

  // Event fired when the user presses a key down
  onKeyUp = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    const searchTerm = e.target.value;

    console.log(`Pressed key ${e.keyCode}. Current value: ${e.target.value}`);

    // User pressed the enter key, update the input and close the suggestions
    const ENTER_KEY = 13;
    if (e.keyCode === ENTER_KEY) {
      this.props.history.push({
        pathname: '/' + 'video_collection',
        search: `?type=athlete_id&id=0&search_term=${searchTerm}`,
        state: {type: 'athlete_id' , id: 0, search_term: searchTerm }
      });
    }

    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onKeyUp,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
            <div className="suggestionDiv">
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={index}
                  onClick={e=> this.onClick(suggestion)}
                >
                  {suggestion.search}
                </li>
              );
            })}
          </ul>
          </div>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
    <div className="acDiv">
        <input className="acInput"
          type="text"
          placeholder="Search athlete name, or events"
          onChange={onChange}
          onKeyUp={onKeyUp}
          value={userInput}
        />
        </div>
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default withRouter(Autocomplete);