import React, { Component } from 'react';
import '../style/header.css';
import Logo from '../static/unx_logo.png';
import '../style/main.css';
import { withRouter, Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import SimpleMenuAlt from './mobileDropdownAlt';

library.add(faUser, faBars, faChevronDown);

class UNXHeaderAlt extends Component { 

    state={
        menuOpen: false,
        toggleDropdown: false
    }

    toggleDiv(toggleDropdown){
        var divState;
        if(this.state.toggleDropdown==true){
          divState = false;
        }else{
          divState = true;
        }
        this.setState({toggleDropdown: divState});
      }

    goTo(str){
        this.props.history.push('/' + str);
    }

    toggleMenu(){
        console.log(this.state.menuOpen);
        var divState;
        if(this.state.menuOpen==true){
          divState = false;
        }else{
          divState = true;
        }
        this.setState({menuOpen: divState});
      }

      goToVideoCollection(str, id, type){
        this.props.history.push({
            pathname: '/' + str,
            search: '?type=' + type + "&id=" + id,
            state: { type: type, id: id }
        })
          if(this.props.location.pathname == '/'+str){
            window.location.reload();
          }
        }
          

    componentDidMount(){
        console.log(this);
    }

    render() {
        return (
            <div className="headerContainer">
            <div className="headerSection" style={{justifyContent: "space-evenly", backgroundColor: "rgb(20,24,32)", alignItems: "center"}}>
                <div className="menuItems" style={{width: "100%", marginLeft: "0px", alignItems: "center", justifyContent: "space-evenly"}}>
                <div className="navDropContain">
                            <div className="headerLink" onClick={e=>this.toggleDiv()}>Videos <FontAwesomeIcon icon="chevron-down"></FontAwesomeIcon></div>
                            {this.state.toggleDropdown == true ? <div className="navDropMenu">
                                <div className="headerLink navDropItem" onClick={e=>this.goTo('dashboard')}>Dashboard</div>
                                <div className="headerLink navDropItem" onClick={e=>this.goToVideoCollection('video_collection', 1, 'video_type_id')}>Competitions</div>
                                <div className="headerLink navDropItem" onClick={e=>this.goToVideoCollection('video_collection', 2, 'video_type_id')}>UNX Fast Forward</div>
                                <div className="headerLink navDropItem" onClick={e=>this.goToVideoCollection('video_collection', 3, 'video_type_id')}>Athlete Full Runs</div>
                            </div> : null}
                        </div>
                    <div>
                        <Link className="headerLink" to="/about_us" style={{fontWeight: "bold"}}>About Us</Link>
                    </div>
                    <div>
                        <Link className="headerLink" to="/unx_championship_series" style={{fontWeight: "bold"}}>UNX Championship Series</Link>
                    </div>
                    <div>
                    <Link className="headerLink" to="/"><img alt='unx_logo' src={Logo} width="150" height="auto"/></Link>
                    </div>
                    <div>
                    <Link className="headerLink" to="/leaderboard" style={{fontWeight: "bold"}}>Leaderboard</Link>
                    </div>
                    <div>
                        <Link className="headerLink" to="/ninja_near_you" style={{fontWeight: "bold"}}>Compete</Link>
                    </div>
                    <div>
                        <a className="headerLink" href="https://www.youtube.com/channel/UClhKt2DfYg9EjgcWbT1LWaA" style={{fontWeight: "bold"}}>YouTube</a>
                    </div>
                </div>
                <div className="menuItemsMobile" style={{marginLeft: "0px", width: "100%"}}>
                    <div>
                        <img alt='unx_logo' src={Logo} width="150" height="auto"/>
                    </div>
                        <div style={{marginLeft: 'auto'}}>
                            <SimpleMenuAlt props={this.props}/>
                        </div> 
                    </div>
            </div>

            </div>
        );
    }
}


export default (withRouter(UNXHeaderAlt));
