import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store';
import AboutUs from './pages/AboutUs';
import UNXChampSeries from './pages/UNXChampSeries';
import ultifitAtHome from './pages/ultifitAtHome';
import NinjaNearYou from './pages/NinjaNearYou';
import Dashboard from './pages/Dashboard';
import VideoPage from './pages/VideoPage';
import VideoCollection from './pages/VideoCollection';
import Leaderboard from './pages/Leaderboard';
import Compete from './pages/Compete';
import HelpPage from './pages/HelpPage';
import './fonts/VIPESSRG.TTF';
import GAListener from './components/GAListener';
import ScrollToTop from 'react-router-scroll-top'

ReactDOM.render(
    <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
            <ScrollToTop>
            <GAListener trackingId="UA-149429314-1">
            <Switch>
                <Route path='/about_us' component={AboutUs}></Route>
                <Route path='/unx_championship_series' component={UNXChampSeries}></Route>
                <Route path='/ultifit_at_home' component={ultifitAtHome}></Route>
                <Route path='/ninja_near_you' component={NinjaNearYou}></Route>
                <Route path='/dashboard' component={Dashboard}></Route>
                <Route path='/video' component={VideoPage}></Route>
                <Route path='/video_collection' component={VideoCollection}></Route>
                <Route path='/leaderboard' component={Leaderboard}></Route>
                <Route path='/compete' component={Compete}></Route>
                <Route path='/help' component={HelpPage}></Route>
                <Route component={Dashboard}></Route>
            </Switch>
            </GAListener>
            </ScrollToTop>
            </BrowserRouter>
         </PersistGate>
    </Provider>
    , document.querySelector('#root')) 

serviceWorker.unregister();
