const INITIAL_STATE = {
  authenticated: false,
	user: null
}

export default function(state = INITIAL_STATE, action){
	switch(action.type){
		case 'authorized user':
			return {
				...state,
				authenticated: true
			}
    case 'unauthorized user':
      return {
        ...state,
        authenticated: false,
        user: null
      }
    case 'set user':
      return {
        ...state,
        user: action.payload
      }
      case 'update user':
      return {
        ...state,
        user: action.payload
      }
	}
	return state
}
