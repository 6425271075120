import React, { Component } from 'react';
import UNXHeaderAlt from "../components/header_alt";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/unx_champ.css';
import '../style/leaderboard.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import unxQualImg from '../static/unx_qualifier_img.png';
import unxSeasonImg from '../static/unx_season_img.png';
import { Row } from 'simple-flexbox';
import { getEventListAsync, getEventResultsAsync, getSeasonStandingsAsync } from '../api/stats';
import Logo from '../static/unx_logo.png';

library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp);

class UNXChampSeries extends Component {
  state={
    openFree: false,
    openPremium: false,
    compArray: [
        {imgSrc: "m_lab_la.jpg", name: "Movement Lab LA", location: "San Dimas, CA", qualNum: "IX", date: "1/4/20", registration: "https://www.eventbrite.com/e/unx-qualifier-movement-lab-la-tickets-84631674617"},
        {imgSrc: "ultimate_ninjas_logo.png", name: "Ultimate Ninjas St. Louis", location: "Chesterfield, MO", qualNum: "X", date: "1/11/20", registration: "https://www.eventbrite.com/e/unx-qualifier-ultimate-ninjas-stl-tickets-84659108673"},
        {imgSrc: "real_life_ninja_academy_logo.jpeg", name: "Real Life Ninja Academy", location: "Stamford, CT", qualNum: "XI", date: "1/18/20", registration: "https://rlnastamford.com/event/unx-qualifier-january-18-2020/"},
        {imgSrc: "jungle_gym_logo.jpg", name: "Jungle Gym", location: "Pinellas Park, FL", qualNum: "XII", date: "1/25/20", registration: "https://junglegym.sites.zenplanner.com/event.cfm?eventId=B6404E92-59E8-4EEF-95C2-C3A84A3FD8E8"}
    ],
    eventArr: [],
    resultsArr: [],
    selectedEvent: '',
    selectedObject: {},
    showLeaderboard: false,
    toggleMobileLeaderboard: false,
    leaderboard: []
  }
    
  toggleDiv(type){
    var divState;
    if(this.state[type]==true){
      divState = false;
    }else{
      divState = true;
    }
    this.setState({[type]: divState});
  }

  goTo(str){
    this.props.history.push('/' + str);
}

getResults = e =>{
    console.log(e.target.value);
    var selectedEvent = e.target.value;
    getEventResultsAsync(this.state.eventArr[selectedEvent].event_id).then(resp=>{
      console.log(resp);
      this.setState({showLeaderboard: true, resultsArr: resp.results, selectedEvent:  this.state.eventArr[selectedEvent].event_name, selectedObj:  this.state.eventArr[selectedEvent]});
    })
  }

      toggleMobileLB(){
            var divState;
            if(this.state.toggleMobileLeaderboard==true){
              divState = false;
            }else{
              divState = true;
            }
            this.setState({toggleMobileLeaderboard: divState});
    }


    componentDidMount() {
        console.log(this);
        getEventListAsync().then(resp=>{
            console.log(resp);
            getSeasonStandingsAsync().then(resp2=>{
              this.setState({eventArr: resp.events, loading: false, leaderboard: resp2.results});
            })
        })
      }

    render() {
      return (
          <div className="homeDiv">
            <UNXHeaderAlt title='' link='' linkTitle=''></UNXHeaderAlt>
            <div id="UNXChampImg">
                            <div style={{position: "absolute", bottom: "0px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(57,177,211)", width: "100%", textAlign: "center", padding:"10px"}}>UNX Championship Series</div>
                  </div>
            <div className="pageContainer">
            <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>- Format -</div>
            <div className="flex-responsive-row">
                <div style={{margin: "10px"}}>
                    <div style={{fontSize: "1.3em", fontWeight: "bold"}}>UNX Qualifier Format</div>
                    <div><img src={unxQualImg} width="100%" height="auto"></img></div>
                </div>
                <div style={{margin: "10px"}}>
                <div style={{fontSize: "1.3em", fontWeight: "bold"}}>UNX Season Format</div>
                    <div><img src={unxSeasonImg} width="100%" height="auto"></img></div>
                </div>
            </div>

            <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>Compete</div>

        {this.state.compArray.map((item, index) => {
            return <div className="whiteCard" style={{ borderBottom: "3px solid rgb(57,177,211)", margin: "10px"}}>
                 <div className="whiteCardContainer">
                    <div className="competitionRow">
                        <div style={{flex: "1"}}><img src={"https://unx-now-main-storage.s3.amazonaws.com/images/gym_logos/" + item.imgSrc} width="100px" height="auto" /></div>
                        <div style={{flex: "1"}}>
                            <div>{item.name}</div>
                            <div>{item.location}</div>
                        </div>
                        <div style={{flex: "1"}}>
                        <div>Open Qualifier {item.qualNum}</div>
                        <div>{item.date}</div>
                        </div>
                        <div style={{flex: "1"}}><button type="button" className="videoButton" onClick={e=>window.open(item.registration, "_blank")}>Register</button></div>
                    </div>
                </div>
            </div>
            })}

        <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>Season Standings</div>

        <div>
        <div className="scoreboard">
                        <div>
                        
                        <div className="leaderboardDivision">
                            
                            <div className="athleteDivision">

                              <div className="scoreboardTitleBox">
                                <div><img alt='unx_logo' src={Logo} width="175" height="auto"/></div>
                                <div style={{textAlign: "center"}}>
                                  <div className="scoreboardTitle">UNX Season Standings</div>
                                    <div className="scoreboardSubTitle">
                                        <span>Top 8 Advance to Championship</span>  
                                      </div>
                                </div>
                                <div><img alt='unx_logo' src={Logo} width="175" height="auto"/></div>
                              </div>

                              <div className="leaderboardDivision">

                              <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                <div className="athleteBox" style={{color: "rgb(255,255,255)", backgroundColor: "rgb(10,10,10)"}}>Men</div>

                              <div style={{marginTop: "10px"}}>
                                <table className="scoreboardTable" style={{width: "100%", borderCollapse: "collapse"}}>
                                  <thead className="tableHeader">
                                    <th></th>
                                    <th style={{textAlign: "left"}}>ATHLETE</th>
                                    <th>Points</th>
                                  </thead>
                                  <tbody>
                                    {this.state.leaderboard.filter(gen=>gen.gender=="Male").map((item, index) => {
                                          return <tr className="standingsBox" style={{borderTop: "4px solid rgb(97, 218, 251)"}}>
                                                  <td className="rankCell">{item.rank}</td>
                                                  <td className="nameCell">{item.first_name} <strong>{item.last_name}</strong></td>
                                                  <td className="locCell">{item.points}</td>
                                          </tr>
                                  })}
                                  </tbody>
                                </table>
                              </div>
                        </div>

                        <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                <div className="athleteBox" style={{color: "rgb(255,255,255)", backgroundColor: "rgb(10,10,10)"}}>Women</div>

                              <div style={{marginTop: "10px"}}>
                                <table className="scoreboardTable" style={{width: "100%", borderCollapse: "collapse"}}>
                                  <thead className="tableHeader">
                                    <th></th>
                                    <th style={{textAlign: "left"}}>ATHLETE</th>
                                    <th>Points</th>
                                  </thead>
                                  <tbody>
                                    {this.state.leaderboard.filter(gen=>gen.gender=="Female").map((item, index) => {
                                          return <tr className="standingsBox" style={{borderTop: "4px solid rgb(97, 218, 251)"}}>
                                                  <td className="rankCell">{item.rank}</td>
                                                  <td className="nameCell">{item.first_name} <strong>{item.last_name}</strong></td>
                                                  <td className="locCell">{item.points}</td>
                                          </tr>
                                  })}
                                  </tbody>
                                </table>
                              </div>
                        </div>

                        </div>
                        </div>
                        </div>
                        
                        </div>
          </div>
        </div>



        <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>Leaderboard</div>

        <div className="lbTitle" style={{fontSize: "1.2em", color: "rgb(60,64,72)"}}>Select a competition to view results!</div>
            <div className="selectOptions">
            <select className="selectBox" style={{fontSize: '14px'}} value={this.state.selectedObject.event_name} onChange={e=>this.getResults(e)}>
                                       <option value=''>Select Competition</option>
                                       {this.state.eventArr.map((item,index)=>{
                                         return <option value={index}>{item.event_name}</option>
                                       })} 
                                    </select>
                              </div>

                  {this.state.showLeaderboard == true ?
                        <div>
                        <div className="subTitle" style={{color: "rgb(125,125,125)"}}>{this.state.selectedEvent}</div>
                        
                        <div className="leaderboardDivision">
                            
                            <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision hideMobile" : "athleteDivision"}>
                                <div className="athleteBox" style={{color: "rgb(60,64,72)", backgroundColor: "rgb(255,255,255)"}}>Men</div>
                                <div className="scrollLink"><span href="javascript:void(0)" onClick={e=>this.toggleMobileLB()}>Show Women</span></div>
                                {this.state.resultsArr.filter(gen=>gen.gender=="Male").map((item, index) => {
                                        return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"}>
                                            <Row horizontal='space-around' vertical='center'>
                                                <div>{item.rank}.</div>
                                                <div>{item.first_name + ' ' + item.last_name}</div>
                                                <div><div>{item.points}</div><div style={{fontSize: ".6em"}}>Pts.</div></div>
                                            </Row>
                                            <Row horizontal='center'>
                                              {item.tiebreaker_time != null ? <div className="tieTime">{item.tiebreaker_time.substr(3)}</div> : null}
                                            </Row>
                                        </div>
                                })}
                        </div>

                        <div className={this.state.toggleMobileLeaderboard == true ? "athleteDivision" : "athleteDivision hideMobile"}>
                                <div className="athleteBox" style={{color: "rgb(60,64,72)", backgroundColor: "rgb(255,255,255)"}}>Women</div>
                                <div className="scrollLink"><span onClick={e=>this.toggleMobileLB()}>Show Men</span></div>
                                {this.state.resultsArr.filter(gen=>gen.gender == "Female").map((item, index) => {
                                    if(item.gender == "Female"){
                                        return <div className={item.points !== null ? "athleteBox completed" : "athleteBox"}>
                                            <Row horizontal='space-around' vertical='center'>
                                                <div>{item.rank}.</div>
                                                <div>{item.first_name + ' ' + item.last_name}</div>
                                                <div><div>{item.points}</div><div style={{fontSize: ".6em"}}>Pts.</div></div>
                                            </Row>
                                            <Row horizontal='center'>
                                                {item.tiebreaker_time != null ? <div className="tieTime">{item.tiebreaker_time.substr(3)}</div> : null}
                                            </Row>
                                        </div>
                                    }
                                })}
                        </div>
                        </div>
                        
                        </div>: null}

            </div>
              <UNXFooter title='' link='' linkTitle=''></UNXFooter>
          </div>
      );
    }
  }


export default UNXChampSeries;
