import React, {Component} from 'react';
import UNXHeader from "../components/header";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/dashboard.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { getVideoCollectionAsync } from '../api/videos';
import loadingImg from '../static/unx_loading.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import queryString from 'query-string';
import { getEventListAsync } from '../api/stats';




library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock);


class VideoCollection extends Component {

  state={
    openFree: false,
    openPremium: false,
    videoArr: [],
    loading: true,
    openDialog: false,
    dialogTitle: "", 
    dialogMsg: "",
    showVideos: false,
    athletePage: false,
    eventArr: [],
    selectedObject: {},
    selectedEvent: ''
  }

  dynamicBackground = (imgSrc) => ({
    backgroundImage: `url(${imgSrc})`
  })

  getResults = e =>{
      const selectedEvent = e.target.value;
      const selectEventFromState = this.state.eventArr[selectedEvent];
      if (selectEventFromState) {
          getVideoCollectionAsync('video_type_id', 3, 'active', selectEventFromState.event_id).then(({ videos })=> {
              this.setState({
                  showVideos: true,
                  videoArr: videos,
                  loading: false,
                  selectedEvent: selectEventFromState.event_name,
                  selectedObj:  this.state.eventArr[selectedEvent]
              });
          }).catch(console.log);
      } else {
          this.setState({ showVideos: false, videoArr: [], loading: false, selectedEvent: '', selectedObj: {}, selectedObject: {} });
      }
  }

    
  toggleDiv(type){
    var divState;
    if(this.state[type]==true){
      divState = false;
    }else{
      divState = true;
    }
    this.setState({[type]: divState});
  }

  goTo(str , id, type, active, premium){
    if(active == 1){
        // if((premium == 1 && this.props.auth.user.sub_status == 'active') || premium==0){
      this.props.history.push({
          pathname: '/' + str,
          search: '?type=' + type + "&id=" + id,
          state: { type: type, id: id }
      })
      // }else{
      //     this.setState({openDialog: true, dialogTitle: "Attention!", dialogMsg: 'This video requires a UNX base subscription. You can upgrade your subscription for $5.99/month'});
      // }
  }
}

upgradeLink = () => {
  this.props.history.push('/checkout');
}

closeDialog = (type) =>{
  this.setState({ openDialog: false, dialogTitle: "", dialogMsg: "" });
}

formatDate(val){
  var year = val.split("-")[0];
  var month = val.split("-")[1];  
  var day = val.split("-")[2];
  return (month + "/" + day + "/" + year);
}

  componentDidUpdate (prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      window.location.reload();
    }
}

  

  componentDidMount(){
    var queryObj = queryString.parse(this.props.location.search);
    const type = queryObj.type;
    if(type == 'video_type_id' && type == 3){
      getEventListAsync().then(resp=>{
        // console.log(resp);
        this.setState({eventArr: resp.events, athletePage: true, loading: false});
    })
    } else {
        let searchTerm = '';
        if (type === 'athlete_id') searchTerm = localStorage.getItem('unx-search-term') || '';

        getVideoCollectionAsync(queryObj.type, queryObj.id, 'active', '', searchTerm).then(resp=>{
          this.setState({videoArr: resp.videos, loading: false, showVideos: true});
      }).catch(err=>{
          console.log(err);
      });
    }
  }

    render() {
        if(this.state.loading == true){
            return (<div className="mainDiv" style={{height: "100vh"}}>
                <UNXHeader title='' link='' linkTitle=''></UNXHeader>
                <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                    <div className="pulseImg">
                    <img src={loadingImg} width="200px" height="auto" />
                    </div>
                    </div>
                    </div>
            )
        }else{
      return (
          <div className="mainDiv">
            <UNXHeader title='' link='' linkTitle=''></UNXHeader>
            <div className="pageContainer" style={{marginTop: "10px"}}>

          {this.state.athletePage == true ?
          <div>
            <div className="lbTitle">Select a competition to view athlete runs!</div>
            <div className="selectOptions">
            <select className="selectBox" style={{fontSize: '14px'}} value={this.state.selectedObject.event_name} onChange={e=>this.getResults(e)}>
                                       <option value=''>Select Competition</option>
                                       {this.state.eventArr.map((item,index)=>{
                                         return <option value={index}>{item.event_name}</option>
                                       })} 
                                    </select>
                              </div>
                      </div> : null
                            }

            {this.state.showVideos == true ?
                <div className="darkContainerRow">
                <div className="flex-container-row" style={{justifyContent: "space-between"}}>
                  </div>
                    <div className="flex-container-row">

                    {(this.state.videoArr || [])
                        .filter(item => item.active === 1)
                        .map((item, index) => {
                        return <div className="videoMainChoice" key={index}>
                        <div className="imgMainContainer">

                                <div className="aspect-ratio-box" style={this.dynamicBackground(item.image_link)} onClick={e=>this.goTo('video', item.video_id, 'video_id', item.active, item.premium)}>
                                {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                        <div className="flexbox-centering">
                                        <div className="viewport-sizing">
                                        {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                            
                            </div>
                            <div className="videoMainMargins">
                                <div className="videoMainTitles">{item.title}</div>
                                {item.active == 1 ? <div>
                                    <div className="videoMainSubTitles">{item.description.split(" | ")[0]}</div>
                                    <div className="videoMainSubTitles">{item.description.split(" | ")[1]}</div>
                                    </div>
                                : <div>
                                    <div className="videoMainSubTitles">{item.description}</div>
                                    <div className="videoMainSubTitles">Coming Soon { item.release_date ?  '| ' + this.formatDate(item.release_date.split("T")[0]) : ''}</div>
                                </div>}
                            </div>
                        </div>
                        })}
                    </div>
                </div> : null
                }
              
              </div>
              <UNXFooter title='' link='' linkTitle=''></UNXFooter>
              <Dialog
                open={this.state.openDialog}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                            {this.state.dialogMsg}      
                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <button type="button" onClick={this.upgradeLink} className="stripeButton">
                                    Upgrade
                    </button>
                            <button type="button" onClick={this.closeDialog} className="stripeButton">
                                    Close
                    </button>
                            </DialogActions>
                        </Dialog>
          </div>
      );
    }
    }
  }


  const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
   };

export default connect(mapStateToProps, mapDispatchToProps)(VideoCollection);
