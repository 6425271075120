import React, { Component } from 'react';
import UNXHeader from "../components/header";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/dashboard.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock,
    faTimes,
    faDollarSign,
    faTv,
    faLaptop,
    faMobileAlt,
    faTabletAlt,
    faAngleDown,
    faAngleUp,
    faPlayCircle,
    faLock,
    faCalendarDay
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Autocomplete from '../components/autocomplete';
import { addVideoClickAsync, getFeaturedVideosAsync, getSearchTextAsync } from '../api/videos';
import loadingImg from '../static/unx_loading.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle, faLock, faCalendarDay);

const API_URL = 'https://vk772942r9.execute-api.us-east-1.amazonaws.com/unxnowprod/';


class Dashboard extends Component {
    state = {
        openFree: false,
        openPremium: false,
        videoArr: [],
        loading: true,
        mainVideo: {},
        searchArr: [],
        openDialog: false,
        dialogTitle: "",
        dialogMsg: "",
    }

    dynamicBackground = (imgSrc) => ({
        backgroundImage: `url(${imgSrc})`
    })

    formatDate(val) {
        const year = val.split("-")[0];
        const month = val.split("-")[1];
        const day = val.split("-")[2];

        return (month + "/" + day + "/" + year);
    }


    toggleDiv(type) {
        let divState;
        divState = this.state[type] !== true;
        this.setState({ [type]: divState });
    }

    goTo(str, id, type, active) {
        if (active === 1) {
            this.props.history.push({
                pathname: '/' + str,
                search: '?type=' + type + "&id=" + id,
                state: { type: type, id: id }
            });
        }
    }

    upgradeLink = () => {
        this.props.history.push('/checkout');
    }

    closeDialog = (_) => {
        this.setState({ openDialog: false, dialogTitle: "", dialogMsg: "" });
    }

    updateMainVideo(vid) {
        if (vid.active === 1) {
            addVideoClickAsync(vid.video_id).then(_ => {
                this.setState({ mainVideo: vid })
            });
        }

    }

    async searchAsync() {
        const url = `${API_URL}videos/get_searchable_text`;
        const response = await fetch(url);
        if (!response.ok) throw new Error('The search could not be performed.');

        const json = await response.json();

        return json.searchArr || [];
    }

    async getFeaturedVideosAsync() {
        const url = `${API_URL}videos/get_featured_videos?sub_status=active`;
        const response = await fetch(url);
        if (!response.ok) throw new Error('The featured videos could not be loaded.');

        const json = await response.json();

        return json.videos || [];
    }

    async componentDidMount() {
        const featuredVideos = await getFeaturedVideosAsync('active');
        this.setState({
            loading: false,
            videoArr: featuredVideos,
            searchArr: await getSearchTextAsync(),
            mainVideo: featuredVideos[0]
        });
    }

    render() {
        if (this.state.loading === true) {
            return (<div className="mainDiv" style={{ height: "100vh" }}>
                    <UNXHeader title='' link='' linkTitle=''></UNXHeader>
                    <div className="flex-container-row" style={{ alignItems: "center", height: "80%" }}>
                        <div className="pulseImg">
                            <img src={loadingImg} width="200px" height="auto"/>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="mainDiv">
                    <UNXHeader title='' link='' linkTitle=''></UNXHeader>
                    <div className="pageContainer" style={{ marginTop: "10px" }}>
                        <div className="flex-dash-main">
                            <div className="mainVideoContainer">

                                <div className='embed-container'>
                                    {this.state.mainVideo.video_type_id === 4 ?
                                        this.state.mainVideo.video_source === 'vm' ?
                                            <iframe
                                                src={"https://vimeo.com/event/" + this.state.mainVideo.video_link + "/embed"}
                                                width="100%"
                                                height="auto"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen"
                                                allowFullScreen>
                                            </iframe>
                                            : <iframe
                                                className="mainVideo"
                                                title="main-video"
                                                src={"https://www.youtube.com/embed/" + this.state.mainVideo.video_link + "?wmode=transparent"}
                                                wmode="Opaque"
                                                width="100%"
                                                height="auto"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                                allowFullScreen>
                                            </iframe>
                                        :
                                        this.state.mainVideo.video_source === 'vm' ?
                                            <iframe
                                                className="mainVideo"
                                                src={"https://player.vimeo.com/video/" + this.state.mainVideo.video_link + "?wmode=transparent"}
                                                wmode="Opaque"
                                                width="100%"
                                                height="auto"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen"
                                                allowFullScreen>
                                            </iframe>
                                            :
                                            <iframe
                                                className="mainVideo"
                                                src={"https://www.youtube.com/embed/" + this.state.mainVideo.video_link + "?wmode=transparent"}
                                                wmode="Opaque"
                                                width="100%"
                                                height="auto"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                                                allowFullScreen>
                                            </iframe>
                                    }
                                </div>

                            </div>
                            <div className="searchVideos">
                                <div>
                                    <Autocomplete
                                        key=''
                                        suggestions={this.state.searchArr}

                                    />
                                    {/* <IntegrationReactSelect></IntegrationReactSelect> */}
                                </div>
                                <div className="darkContainerCol">
                                    <div style={{
                                        color: "rgb(175,175,175",
                                        fontWeight: "bold",
                                        padding: "5px"
                                    }}>Trending
                                    </div>
                                    {this.state.videoArr.slice(0, 4).map((item, index) => {
                                        // if(this.props.auth.user.sub_status == 'active'){
                                        return <div
                                            key={index}
                                            className="videoSummaryChoice"
                                            onClick={e => this.updateMainVideo(item)}>
                                            <div><img className="roundedSummaryImage" src={item.image_link}
                                                      alt="actionPhoto" width="130px" height="auto"/></div>
                                            <div className="videoSummaryMargins">
                                                <div className="videoSummmaryTitles">{item.title}</div>
                                                <div className="videoSummmarySubTitles">{item.description}</div>
                                            </div>
                                        </div>
                                        //     }else if(item.premium == 0){
                                        //     return <div className="videoSummaryChoice" onClick={e=>this.updateMainVideo(item)}>
                                        //         <div><img className="roundedSummaryImage" src={item.image_link} alt="actionPhoto" width="130px" height="auto"/></div>
                                        //         <div className="videoSummaryMargins">
                                        //             <div className="videoSummmaryTitles">{item.title}</div>
                                        //             <div className="videoSummmarySubTitles">{item.description}</div>
                                        //         </div>
                                        //     </div>
                                        // }
                                    })}

                                </div>
                            </div>
                        </div>

                        <div id="trendingMobile">
                            <div className="darkContainerRow">
                                <div className="flex-container-row" style={{ justifyContent: "space-between" }}>
                                    <div className="darkContainerTitle">Trending</div>
                                    {/* <div className="darkContainerTitle" style={{color: "rgb(77,198,231)", cursor: "pointer"}} onClick={e=>this.goTo('video_collection', 1, 'video_type_id', 1, 0)}>See All</div> */}
                                </div>
                                <div className="flex-container-row">
                                    {this.state.videoArr.slice(0, 4).map((item, index) => {
                                        return <div key={index} className="videoMainChoice">
                                            <div className="imgMainContainer">

                                                {/* <img className="roundedMainImage" src={actionPhoto} alt="actionPhoto" width="250px" height="auto"/> */}

                                                <div className="aspect-ratio-box"
                                                     style={this.dynamicBackground(item.image_link)}
                                                     onClick={e => this.goTo('video', item.video_id, 'video_id', item.active, item.premium)}>
                                                    {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                                    <div
                                                        className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                                        <div className="flexbox-centering">
                                                            <div className="viewport-sizing">
                                                                {item.active == 1 ? <div>
                                                                    {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                                                    <FontAwesomeIcon
                                                                        icon="play-circle"></FontAwesomeIcon>
                                                                    {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                                                </div> : <div>
                                                                    <div><FontAwesomeIcon
                                                                        icon="calendar-day"></FontAwesomeIcon></div>
                                                                    <div className="comingSoonText">COMING SOON</div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="videoMainMargins">
                                                <div className="videoMainTitles">{item.title}</div>
                                                <div className="videoMainSubTitles">{item.description}</div>
                                                {item.active == 1 ? <div
                                                        className="videoMainSubTitles">{this.formatDate(item.date_added.split("T")[0])}</div>
                                                    : <div className="videoMainSubTitles">Coming Soon
                                                        | {this.formatDate(item.release_date.split("T")[0])}</div>}
                                            </div>
                                        </div>
                                    })}

                                </div>
                            </div>
                        </div>

                        <div className="darkContainerRow">
                            <div className="flex-container-row" style={{ justifyContent: "space-between" }}>
                                <div className="darkContainerTitle">UNX Competitions</div>
                                <div className="darkContainerTitle"
                                     style={{ color: "rgb(77,198,231)", cursor: "pointer" }}
                                     onClick={e => this.goTo('video_collection', 1, 'video_type_id', 1, 0)}>See All
                                </div>
                            </div>
                            <div className="flex-container-row">
                                {this.state.videoArr.filter(vid => vid.video_type_id == 1).map((item, index) => {
                                    if (index < 4) {
                                        return <div key={index} className="videoMainChoice">
                                            <div className="imgMainContainer">

                                                {/* <img className="roundedMainImage" src={actionPhoto} alt="actionPhoto" width="250px" height="auto"/> */}

                                                <div className="aspect-ratio-box"
                                                     style={this.dynamicBackground(item.image_link)}
                                                     onClick={e => this.goTo('video', item.video_id, 'video_id', item.active, item.premium)}>
                                                    {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                                    <div
                                                        className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                                        <div className="flexbox-centering">
                                                            <div className="viewport-sizing">
                                                                {item.active == 1 ? <div>
                                                                    {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                                                    <FontAwesomeIcon
                                                                        icon="play-circle"></FontAwesomeIcon>
                                                                    {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                                                </div> : <div>
                                                                    <div><FontAwesomeIcon
                                                                        icon="calendar-day"></FontAwesomeIcon></div>
                                                                    <div className="comingSoonText">COMING SOON</div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="videoMainMargins">
                                                <div className="videoMainTitles">{item.title}</div>
                                                <div className="videoMainSubTitles">{item.description}</div>
                                                {item.active == 1 ? <div
                                                        className="videoMainSubTitles">{this.formatDate(item.date_added.split("T")[0])}</div>
                                                    : <div className="videoMainSubTitles">Coming Soon
                                                        | {this.formatDate(item.release_date.split("T")[0])}</div>}
                                            </div>
                                        </div>
                                    }
                                })}

                            </div>
                        </div>

                        <div className="darkContainerRow">
                            <div className="flex-container-row" style={{ justifyContent: "space-between" }}>
                                <div className="darkContainerTitle">UNX Fast Forward</div>
                                <div className="darkContainerTitle"
                                     style={{ color: "rgb(77,198,231)", cursor: "pointer" }}
                                     onClick={e => this.goTo('video_collection', 2, 'video_type_id', 1, 0)}>See All
                                </div>
                            </div>
                            <div className="flex-container-row">

                                {this.state.videoArr.filter(vid => vid.video_type_id == 2).map((item, index) => {
                                    if (index < 4) {
                                        return <div key={index} className="videoMainChoice">
                                            <div className="imgMainContainer">

                                                {/* <img className="roundedMainImage" src={actionPhoto} alt="actionPhoto" width="250px" height="auto"/> */}

                                                <div className="aspect-ratio-box"
                                                     style={this.dynamicBackground(item.image_link)}
                                                     onClick={e => this.goTo('video', item.video_id, 'video_id', item.active, item.premium)}>
                                                    {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                                    <div
                                                        className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                                        <div className="flexbox-centering">
                                                            <div className="viewport-sizing">
                                                                {item.active == 1 ? <div>
                                                                    {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                                                    <FontAwesomeIcon
                                                                        icon="play-circle"></FontAwesomeIcon>
                                                                    {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                                                </div> : <div>
                                                                    <div><FontAwesomeIcon
                                                                        icon="calendar-day"></FontAwesomeIcon></div>
                                                                    <div className="comingSoonText">COMING SOON</div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="videoMainMargins">
                                                <div className="videoMainTitles">{item.title}</div>
                                                <div className="videoMainSubTitles">{item.description}</div>
                                                {item.active == 1 ? <div
                                                        className="videoMainSubTitles">{this.formatDate(item.date_added.split("T")[0])}</div>
                                                    : <div className="videoMainSubTitles">Coming Soon
                                                        | {this.formatDate(item.release_date.split("T")[0])}</div>}
                                            </div>
                                        </div>
                                    }
                                })}

                            </div>
                        </div>

                        <div className="darkContainerRow">
                            <div className="flex-container-row" style={{ justifyContent: "space-between" }}>
                                <div className="darkContainerTitle">UNX Full Runs</div>
                                <div className="darkContainerTitle"
                                     style={{ color: "rgb(77,198,231)", cursor: "pointer" }}
                                     onClick={e => this.goTo('video_collection', 3, 'video_type_id', 1, 0)}>See All
                                </div>
                            </div>
                            <div className="flex-container-row">

                                {this.state.videoArr.filter(vid => vid.video_type_id == 3).map((item, index) => {
                                    if (index < 4) {
                                        return <div key={index} className="videoMainChoice">
                                            <div className="imgMainContainer">

                                                <div className="aspect-ratio-box"
                                                     style={this.dynamicBackground(item.image_link)}
                                                     onClick={e => this.goTo('video', item.video_id, 'video_id', item.active, item.premium)}>
                                                    {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                                    <div
                                                        className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                                        <div className="flexbox-centering">
                                                            <div className="viewport-sizing">
                                                                {item.active == 1 ? <div>
                                                                    {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                                                    <FontAwesomeIcon
                                                                        icon="play-circle"></FontAwesomeIcon>
                                                                    {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                                                </div> : <div>
                                                                    <div><FontAwesomeIcon
                                                                        icon="calendar-day"></FontAwesomeIcon></div>
                                                                    <div className="comingSoonText">COMING SOON</div>
                                                                </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="videoMainMargins">
                                                <div className="videoMainTitles">{item.title}</div>
                                                {item.active == 1 ? <div>
                                                        <div
                                                            className="videoMainSubTitles">{item.description.split(" | ")[0]}</div>
                                                        <div
                                                            className="videoMainSubTitles">{item.description.split(" | ")[1]}</div>
                                                    </div>
                                                    : <div>
                                                        <div className="videoMainSubTitles">{item.description}</div>
                                                        <div className="videoMainSubTitles">Coming Soon
                                                            | {this.formatDate(item.release_date.split("T")[0])}</div>
                                                    </div>}
                                            </div>
                                        </div>
                                    }
                                })}
                            </div>
                        </div>

                    </div>
                    <UNXFooter title='' link='' linkTitle=''></UNXFooter>
                    <Dialog
                        open={this.state.openDialog}
                        // onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.dialogMsg}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <button type="button" onClick={this.upgradeLink} className="stripeButton">
                                Upgrade
                            </button>
                            <button type="button" onClick={this.closeDialog} className="stripeButton">
                                Close
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}


const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
