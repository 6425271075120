import React, {Component} from 'react';
import UNXHeader from "../components/header";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/dashboard.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {getRequestedVideoAsync} from '../api/videos';
import loadingImg from '../static/unx_loading.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp, faPlayCircle);

class VideoPage extends Component {
  state={
    openFree: false,
    openPremium: false,
    mainVideo: {},
    relatedVideos: [],
    loading: true,
    openDialog: false,
    dialogTitle: "", 
    dialogMsg: "",
  }

  dynamicBackground = (imgSrc) => ({
    backgroundImage: `url(${imgSrc})`
  })

  goTo(str , id, type, active, premium){
    if(active == 1){
        // if((premium == 1 && this.props.auth.user.sub_status == 'active') || premium==0){
      this.props.history.push({
          pathname: '/' + str,
          search: '?type=' + type + "&id=" + id,
          state: { type: type, id: id }
      })
      // getRequestedVideoAsync(id, this.props.auth.user.sub_status).then(resp=>{
        getRequestedVideoAsync(id, 'active').then(resp=>{
        // console.log(resp);
        this.setState({mainVideo: resp.video[0], relatedVideos: resp.related});
    }).catch(err=>{
        console.log(err);
    })
      // }else{
      //     this.setState({openDialog: true, dialogTitle: "Attention!", dialogMsg: 'This video requires a UNX base subscription. You can upgrade your subscription for $5.99/month'});
      // }
  }
}


//   goTo(str , id, type, active){
//     if(active == 1){
//     this.props.history.push({
//         pathname: '/' + str,
//         search: '?id=' + id,
//         state: { type: type, id: id }
//       })
//       getRequestedVideoAsync(id).then(resp=>{
//             console.log(resp);
//             this.setState({mainVideo: resp.video[0], relatedVideos: resp.related});
//         }).catch(err=>{
//             console.log(err);
//         })
//     }
// }

upgradeLink = () => {
    this.props.history.push('/checkout');
}

closeDialog = (type) =>{
    this.setState({ openDialog: false, dialogTitle: "", dialogMsg: "" });
}

formatDate(val){
    var year = val.split("-")[0];
    var month = val.split("-")[1];  
    var day = val.split("-")[2];
    return (month + "/" + day + "/" + year);
  }


  componentDidMount(){
    //   console.log(this.props.location.state.video_id);
      // getRequestedVideoAsync(this.props.location.state.id, this.props.auth.user.sub_status).then(resp=>{
        getRequestedVideoAsync(this.props.location.state.id, 'active').then(resp=>{
        //   console.log(resp);
          this.setState({mainVideo: resp.video[0], relatedVideos: resp.related, loading: false});
      }).catch(err=>{
          console.log(err);
      })
  }

    render() {
        if(this.state.loading == true){
            return (<div className="mainDiv" style={{height: "100vh"}}>
                <UNXHeader title='' link='' linkTitle=''></UNXHeader>
                <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                    <div className="pulseImg">
                    <img src={loadingImg} width="200px" height="auto" />
                    </div>
                    </div>
                    </div>
            )
        }else{
            return (
                <div className="mainDiv">
                  <UNXHeader title='' link='' linkTitle=''></UNXHeader>
                  <div className="pageContainer" style={{marginTop: "10px"}}>
                  <div className="flex-dash-main">
                      <div className="mainVideoContainer">
                          
                      <div class='embed-container'>
                    {this.state.mainVideo.video_type_id == 4 ? 
                        this.state.mainVideo.video_source == 'vm' ?
                            <iframe src={"https://vimeo.com/event/" + this.state.mainVideo.video_link + "/embed"} width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            : <iframe className="mainVideo" src={"https://www.youtube.com/embed/" + this.state.mainVideo.video_link + "?wmode=transparent"} wmode="Opaque" width="100%" height="auto" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                        :
                            this.state.mainVideo.video_source == 'vm' ?
                                <iframe className="mainVideo" src={"https://player.vimeo.com/video/" + this.state.mainVideo.video_link + "?wmode=transparent"} wmode="Opaque" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                                :
                                <iframe className="mainVideo" src={"https://www.youtube.com/embed/" + this.state.mainVideo.video_link + "?wmode=transparent"} wmode="Opaque" width="100%" height="auto" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
                        }
                    </div>
                          
                      </div>
                    </div>
      
                    <div className="darkContainerRow">
                        <div className="darkContainerTitle">Related Videos</div>
                          <div className="flex-container-row">
      
                          {this.state.relatedVideos.map((item, index) => {
                              return <div className="videoMainChoice">
                              <div className="imgMainContainer">
      
                                      <div className="aspect-ratio-box" style={this.dynamicBackground(item.image_link)} onClick={e=>this.goTo('video', item.video_id, 'video_id', item.active, item.premium)}>
                                      {/* <div className={item.active == 1 && (item.premium == 0 || (this.props.auth.user.sub_status == 'active')) ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}> */}
                                      <div className={item.active == 1 ? "aspect-ratio-box-inside" : "aspect-ratio-box-inside-locked"}>
                                              <div className="flexbox-centering">
                                              <div className="viewport-sizing">
                                              {item.active == 1 ? <div>
                                            {/* {this.props.auth.user.sub_status == 'active' || item.premium == 0 ? */}
                                            <FontAwesomeIcon icon="play-circle"></FontAwesomeIcon>
                                            {/* : <div>
                                            <div><FontAwesomeIcon icon="lock"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">UPGRADE</div>
                                            </div>
                                            } */}
                                            </div>: <div>
                                            <div><FontAwesomeIcon icon="calendar-day"></FontAwesomeIcon></div>
                                            <div className="comingSoonText">COMING SOON</div>
                                            </div>
                                        }
                                              </div>
                                              </div>
                                          </div>
                                          </div>
                                  
                                  </div>
                                  <div className="videoMainMargins">
                                      <div className="videoMainTitles">{item.title}</div>
                                      {item.active == 1 ? <div>
                                          <div className="videoMainSubTitles">{item.description.split(" | ")[0]}</div>
                                          <div className="videoMainSubTitles">{item.description.split(" | ")[1]}</div>
                                          </div>
                                      : <div>
                                          <div className="videoMainSubTitles">{item.description}</div>
                                          <div className="videoMainSubTitles">Coming Soon | {this.formatDate(item.release_date.split("T")[0])}</div>
                                      </div>}
                                  </div>
                              </div>
                          })}
                          </div>
                      </div>
                    
                    </div>
                    <UNXFooter title='' link='' linkTitle=''></UNXFooter>
                    <Dialog
                open={this.state.openDialog}
                // onClose={handleClose}
                aria-labelledby="form-dialog-title"
                >
                <DialogTitle id="form-dialog-title">{this.state.dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                            {this.state.dialogMsg}      
                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            <button type="button" onClick={this.upgradeLink} className="stripeButton">
                                    Upgrade
                    </button>
                            <button type="button" onClick={this.closeDialog} className="stripeButton">
                                    Close
                    </button>
                            </DialogActions>
                        </Dialog>
                  </div>
                
            );
        }
      
    }
  }


  const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
   };

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
