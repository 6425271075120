import React, {Component} from 'react';
import UNXHeaderAlt from "../components/header_alt";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/unx_champ.css';
import '../style/leaderboard.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faTimes,  faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import unxLogo from '../static/unx_with_background.jpg';
import nnlLogo from '../static/nnl_logo.png';
import awgLogo from '../static/awg_logo.png';
import unaaLogo from '../static/unaa_logo.png';




library.add(faClock, faTimes, faDollarSign, faTv, faLaptop, faMobileAlt, faTabletAlt, faAngleDown, faAngleUp);


class NinjaNearYou extends Component {

  state={
    compArray: [
      {leagueImg: "unx_with_background.jpg", imgSrc: "m_lab_la.jpg", name: "Movement Lab LA", location: "San Dimas, CA", qualNum: "IX", date: "1/4/20", registration: "https://www.eventbrite.com/e/unx-qualifier-movement-lab-la-tickets-84631674617"},
      {leagueImg: "unx_with_background.jpg", imgSrc: "ultimate_ninjas_logo.png", name: "Ultimate Ninjas St. Louis", location: "Chesterfield, MO", qualNum: "X", date: "1/11/20", registration: "https://www.eventbrite.com/e/unx-qualifier-ultimate-ninjas-stl-tickets-84659108673"},
      {leagueImg: "unx_with_background.jpg", imgSrc: "real_life_ninja_academy_logo.jpeg", name: "Real Life Ninja Academy", location: "Stamford, CT", qualNum: "XI", date: "1/18/20", registration: "https://rlnastamford.com/event/unx-qualifier-january-18-2020/"},
      {leagueImg: "unx_with_background.jpg", imgSrc: "jungle_gym_logo.jpg", name: "Jungle Gym", location: "Pinellas Park, FL", qualNum: "XII", date: "1/25/20", registration: "https://junglegym.sites.zenplanner.com/event.cfm?eventId=B6404E92-59E8-4EEF-95C2-C3A84A3FD8E8"}
  ],
  }


    componentDidMount() {
        console.log(this);
      }

    render() {
      return (
          <div className="homeDiv">
            <UNXHeaderAlt title='' link='' linkTitle=''></UNXHeaderAlt>
            <div id="NinjaNearYouImg">
                            <div style={{position: "absolute", bottom: "0px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(57,177,211)", width: "100%", textAlign: "center", padding:"10px"}}>Ninja Near You</div>
                  </div>
            <div className="pageContainer">
            <div style={{marginTop: "25px", fontFamily: "VIPESSRG", fontSize: "2em", color: "rgb(20,24,32)"}}>Competitions</div>
            <div>Wherever you are, we can help you find a ninja competition. Filter our calendar by date, league or location and find a place to ninja near you! Click the logo to find a competition.</div>

            <div className="flex-container-row" style={{justifyContent: "space-evenly", alignItems: "center", marginTop: "20px"}}>
                            <div >
                              <img src={unxLogo} width="175px" height="auto" />
                            </div>
                            <div>
                              <a href="https://www.nationalninja.com/schedule.html" target="_blank"><img src={nnlLogo} width="175px" height="auto" /></a>
                            </div>
                            <div>
                            <a href="https://www.athletewarriorgames.com/schedule" target="_blank"><img src={awgLogo} width="175px" height="auto" /></a>
                            </div>
                            <div>
                            <a href="https://www.ultimateninja.net/schedule/" target="_blank"><img src={unaaLogo} width="175px" height="auto" /></a>
                            </div>
                        </div>
<div style={{fontSize: "1.5em"}}>November (UNX Competitions)</div>
        {this.state.compArray.map((item, index) => {
            return <div className="whiteCard" style={{ borderBottom: "3px solid rgb(57,177,211)", margin: "10px"}}>
                 <div className="whiteCardContainer">
                    <div className="competitionRow">
                    <div style={{flex: "1", margin: "10px"}}><img src={require("../static/" + item.leagueImg)} width="75px" height="auto" /></div>
                        <div style={{flex: "1"}}><img src={"https://unx-now-main-storage.s3.amazonaws.com/images/gym_logos/" + item.imgSrc} width="100px" height="auto" /></div>
                        <div style={{flex: "1"}}>
                            <div>{item.name}</div>
                            <div>{item.location}</div>
                        </div>
                        <div style={{flex: "1"}}>
                        <div>Open Qualifier {item.qualNum}</div>
                        <div>{item.date}</div>
                        </div>
                        <div style={{flex: "1"}}><button type="button" className="videoButton" onClick={e=>window.open(item.registration, "_blank")}>Register</button></div>
                    </div>
                </div>
            </div>
            })}

            </div>
              <UNXFooter title='' link='' linkTitle=''></UNXFooter>
          </div>
      );
    }
  }


export default NinjaNearYou;
