import React, {Component} from 'react';
import UNXHeader from "../components/header";
import UNXFooter from "../components/footer";
import logo from '../static/unx_logo.png';
import '../style/compete.css';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faArrowCircleDown, faArrowDown} from '@fortawesome/free-solid-svg-icons';

library.add(faCheckCircle, faArrowCircleDown, faArrowDown);

class Compete extends Component {

  state={
    
  }

componentDidMount(){
    
  }


    render() {
      return (
        <div className="App">
          <UNXHeader title='' link='' linkTitle=''></UNXHeader>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <div style={{fontSize: "20px", color: "#61dafb", fontWeight: "normal", fontFamily: "VIPESSRG", letterSpacing: "3px"}}>
            A Home for Everything Ninja
          </div>
              <div align="left">
                <div className="flex-container" style={{marginTop: "20px"}}>
                <div className="unxCompOverview">
              <div style={{color: "#ffffff", fontSize: "24px", marginTop: "20px", color: "#61DAFB", fontWeight: "bold"}}>UNX Competition Overview</div>
              <div>
              <div className="listItem">  
                <span className="listIcon"><FontAwesomeIcon icon="check-circle"/></span><span className="listText"><strong>$75,000</strong> prize money across entire UNX season</span>
              </div>
              <div className="listItem">
                <span className="listIcon"><FontAwesomeIcon icon="check-circle"/></span><span className="listText">UNX season standings determines who qualifies for the UNX Championship</span>
              </div>
              <div className="listItem">
                <span className="listIcon"><FontAwesomeIcon icon="check-circle"/></span><span className="listText">Season standings based on 3 UNX Major events</span>
              </div>
              <div className="listItem">
                <span className="listIcon"><FontAwesomeIcon icon="check-circle"/></span><span className="listText">Travel and Accommodation for all athletes participating in Majors and Championship events are covered</span>
              </div>
              <div className="listItem">
                <span className="listIcon"><FontAwesomeIcon icon="check-circle"/></span><span className="listText">4 open qualifiers held before each UNX Major, where top 3 men/women qualify for the Major</span>
              </div>
            </div>
            </div>
            <div className="unxNetOverview">
              <div style={{color: "#ffffff", fontSize: "24px", marginTop: "20px", color: "#61DAFB", fontWeight: "bold"}}>UNX Network Overview</div>
              <div style={{color: "#ffffff", fontSize: "20px", marginTop: "20px"}}>For a monthly fee UNX subscribers will have exclusive access to:</div>
              <ul style={{color: "#ffffff", fontSize: "20px"}}>            
                <li>Live streamed competitions </li>
                <li>Live streamed interviews with the top athletes in the sport</li>
                <li>Ability to interact live with your favorite athletes </li>
                <li>On demand programming such as highlight shows, training tips, and other surprises</li>
                <li>UNX world ranking system of athletes</li>
                <li>UNX gym directory</li>
                <li>UNX merchandise</li>
                <li>And much more</li>
              </ul>
            </div>
            </div>
            <div style={{color: "#ffffff", fontSize: "24px", marginTop: "20px", color: "#61DAFB", fontWeight: "bold"}}>Competition Dates</div>
            <div style={{marginLeft: "15px", fontSize: "20px"}}>
              <div>Open Qualifier I - 9/7 - <a style={{color: "#61dafb"}} href="https://www.eventbrite.com/e/unx-qualifier-ultimate-ninjas-naperville-tickets-69313980983">Ultimate Ninjas Naperville</a></div>
              <div>Open Qualifier II - 9/28 - <a style={{color: "#61dafb"}} href="https://ninjaquest.pike13.com/courses/214458">Ninja Quest</a></div>
              <div>Open Qualifier III - 10/5 - <a style={{color: "#61dafb"}} href="https://clients.mindbodyonline.com/ASP/main_enroll.asp?studioid=30758&tg=&vt=&lvl=&stype=-104&view=day&trn=0&page=&catid=&prodid=&date=8%2f24%2f2019&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1">Alternate Routes</a></div>
              <div>Open Qualifier IV - 10/19 - <a style={{color: "#61dafb"}} href="https://clients.mindbodyonline.com/ASP/main_enroll.asp?studioid=736554&tg=&vt=&lvl=&stype=-8&view=day&trn=0&page=&catid=&prodid=&date=9%2f28%2f2019&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1">Traverse Fitness</a></div>
              <div>Major I - 11/2</div>
              <div>Open Qualifiers V-VIII - Coming Soon!</div>
              <div>Major II - 12/14</div>
              <div>Open Qualifiers IX-XII - Coming Soon!</div>
              <div>Major III - 2/1</div>
              <div>Champ - 2/15</div>
              <div>Additional Qualifier dates and locations coming soon!</div>
            </div>
            <div style={{color: "#ffffff", fontSize: "24px", marginTop: "20px", color: "#61DAFB", fontWeight: "bold"}}>Competition Format</div>
            <div className="exBorder">
            <div className="exTitle">UNX Major Qualification</div>
            <div className="flex-container2">
              <div>
              <div className="qualBox">
                <div className="qualTitle">UNX Open Qualifier</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div>
              <div className="qualBox">
                <div className="qualTitle">UNX Open Qualifier</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div>
              <div className="qualBox">
                <div className="qualTitle">UNX Open Qualifier</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div>
              <div className="qualBox">
                <div className="qualTitle">UNX Open Qualifier</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
            </div>
            <div className="majorBox">
                <div className="qualTitle" align="middle">UNX Major Event</div>
                <div className="qualDesc"></div>
              </div>
              </div>
  
              <div className="exBorder">
            <div className="exTitle">UNX Championship Qualification</div>
            <div className="flex-container2">
  
              <div>
              <div>
              <div className="qualBox">
                <div className="qualTitle">4 Open Qualifiers (I-IV)</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div className="majorBox">
                <div className="qualTitle">UNX Major Event I</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div>
              <div>
              <div className="qualBox">
                <div className="qualTitle">4 Open Qualifiers (V-VIII)</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div className="majorBox">
                <div className="qualTitle">UNX Major Event II</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div>
              <div>
              <div className="qualBox">
                <div className="qualTitle">4 Open Qualifiers (IX-XII)</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
              <div className="majorBox">
                <div className="qualTitle" >UNX Major Event III</div>
                <div className="qualDesc"></div>
              </div>
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
            </div>
  
              <div>
              <div className="standingsBox">
                <div className="qualTitle" align="middle" style={{color: "#ffffff"}}>UNX Season Standings</div>
                <div className="qualDesc"></div>
              </div>
              
              <div align="middle">
              <span className="listIcon"><FontAwesomeIcon icon="arrow-down"/></span>
              </div>
              </div>
  
              <div className="championshipBox">
                <div className="qualTitle" align="middle" style={{color: "#ffffff"}}>UNX Championship</div>
                <div className="qualDesc"></div>
              </div>
              
  
              </div>
          </div>
        </header>
        <UNXFooter title='' link='' linkTitle=''></UNXFooter>
      </div>
      );
              
    }
  }


  const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
   };

export default connect(mapStateToProps, mapDispatchToProps)(Compete);
