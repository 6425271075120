import React, {Component} from 'react';
import UNXHeader from "../components/header";
import UNXFooter from "../components/footer";
import '../style/main.css';
import '../style/account.css';
import '../style/home.css';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCreditCard} from '@fortawesome/free-solid-svg-icons';
import loadingImg from '../static/unx_loading.png';

library.add(faCreditCard);


class HelpPage extends Component {

  state={
    
  }

componentDidMount(){
    
  }


    render() {
      if(this.state.loading == true){
        return (<div className="mainDiv" style={{height: "100vh"}}>
            <UNXHeader title='' link='' linkTitle=''></UNXHeader>
            <div className="flex-container-row" style={{alignItems: "center", height: "80%"}}>
                <div className="pulseImg">
                <img src={loadingImg} width="200px" height="auto" />
                </div>
                </div>
                </div>
        )
    }else{
      return (
          <div className="mainDiv">
            <UNXHeader title='' link='' linkTitle=''></UNXHeader>
            <div className="pageContainer" style={{marginTop: "10px"}}>
            
            <div className="accountTitle">Looking for some help? We've got you covered!</div>
            <div className="flex-account-main">
                
                <div style={{flexGrow: "1", margin: "10px"}}>
                <div className="acctContainerTitle">Technical Support</div>
                <div className="acctContainerCol">
                  <div style={{margin: "10px"}}>Sign in / Sign up Issues</div>
                  <div style={{margin: "10px"}}>Video Issues</div>
                  <div style={{margin: "10px"}}>Billing Issues</div>
                  <div style={{margin: "10px"}}>Account Update Issues</div>
                </div>
                <div style={{textAlign: "center", borderRadius: "0px 0px 3px 3px", backgroundColor: "rgb(88,219,136)", color: "#ffffff", fontSize: "1.5em", padding: "10px", fontWeight: "bold", cursor: "pointer"}}>
                <a href="mailto: support@unxnow.com" style={{textDecoration: "none", color: "#ffffff"}}>support@unxnow.com</a>
                </div>
                
                </div>

                <div style={{flexGrow: "1", margin: "10px"}}>
                <div className="acctContainerTitle">General Support</div>
                <div className="acctContainerCol">
                  <div style={{margin: "10px"}}>Competition Information</div>
                  <div style={{margin: "10px"}}>Hosting UNX Events</div>
                  <div style={{margin: "10px"}}>General Inquiry</div>
                  <div style={{margin: "10px"}}>About UNX</div>
                </div>
                <div style={{textAlign: "center", borderRadius: "0px 0px 3px 3px", backgroundColor: "rgb(88,219,136)", color: "#ffffff", fontSize: "1.5em", padding: "10px", fontWeight: "bold", cursor: "pointer"}}>
                <a href="mailto: info@unxnow.com" style={{textDecoration: "none", color: "#ffffff"}}>info@unxnow.com</a>
                </div>
                
                </div>
                </div>

              </div>
              <UNXFooter title='' link='' linkTitle=''></UNXFooter>

          </div>
      );
              }
    }
  }


  const mapStateToProps = state => ({
    auth: state.auth
  });

const mapDispatchToProps = {
   };

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage);
